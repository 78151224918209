interface IAEvent {
  ec: string;
  ea: string;
  el: string;
  ev?: string;
  ni?: boolean;
}

export default function aEvent(event: IAEvent) {
  const {
    ec: eventCategory,
    ea: eventAction,
    ev: eventValue = "",
    el: eventLabel,
    ni: nonInteractionHit = true,
  } = event;

  window.dataLayer = window.dataLayer || [];

  dataLayer.push({
    event: "custom-event",
    eventCategory,
    eventAction,
    eventLabel,
    eventValue,
    nonInteractionHit,
  });
}

interface IEmailEvent {
  email: string;
  uid: string;
  ea: string;
}

export function emailEvent(event: IEmailEvent) {
  const { ea, email, uid } = event;

  window.dataLayer = window.dataLayer || [];

  dataLayer.push({
    event: "email-event",
    eventAction: ea,
    eventEmail: email,
    eventUid: uid,
  });
}
