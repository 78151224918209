import React from "react";
import Link from "gatsby-link";
import SimpleSlider from "../SimpleSlider";
import ICaseStudy from "../../interfaces/ICaseStudy";
import StrapiImage from "../StrapiImage";
import aEvent from "../../common/aEvent";

import {
  CaseHeading,
  CaseImageWrapper,
  CaseStudiesItem,
  CaseStudiesItemPadding,
  CaseText,
} from "./styles";
import { Typo24, Typo27 } from "../Typography";

interface Props {
  items: ICaseStudy[];
  padding?: number;
  paddingDesktop?: number;
}

const CaseStudiesSlider: React.FC<Props> = (props) => {
  const { items, padding, paddingDesktop } = props;

  const currentPadding = typeof padding !== "undefined" ? padding : 22;
  const currenteDesktopPadding =
    typeof paddingDesktop !== "undefined" ? paddingDesktop : 83;

  return (
    <SimpleSlider
      padding={currentPadding}
      paddingDesktop={currenteDesktopPadding}
      dots={true}
      items={items}
      formatItems={true}
      interactionCallback={() => {
        aEvent({
          ec: "Slider Events",
          ea: "slider-interaction",
          el: "case-studies",
          ni: false,
        });
      }}
      renderItem={(item: ICaseStudy) => {
        const { slug, media, heading, position } = item;

        return (
          <CaseStudiesItemPadding>
            <Link to={`/success-story/${slug}/`}>
              <CaseStudiesItem>
                <CaseImageWrapper>
                  {media && <StrapiImage image={media} />}
                </CaseImageWrapper>
                <CaseHeading>
                  <Typo24 color="#658AC8" textAlign="center">
                    {heading}
                  </Typo24>
                </CaseHeading>
                <CaseText>
                  <Typo27
                    color="rgba(11, 38, 76, 0.6)"
                    fontWeight="500"
                    textAlign="center"
                  >
                    {position}
                  </Typo27>
                </CaseText>
              </CaseStudiesItem>
            </Link>
          </CaseStudiesItemPadding>
        );
      }}
    />
  );
};

export default CaseStudiesSlider;
